<template>
  <div>
    <div class="c-form-control">
      <label class="c-form-control__label">{{ label }}</label>
      <div class="u-position-relative">
        <input
          :type="showPassword ? 'text' : type"
          class="c-form-control__input"
          :class="{
            'c-form-control__input--block': block,
            'c-form-control__input--icon with-flag': icon !== '',
            'c-form-control__input--password': type === 'password',
            'c-form-control__required': mandotary,
          }"
          :id="inputId"
          :value="value"
          @input="(e) => $emit('input', e.target.value)"
          @blur="(e) => (e.target.value = value)"
          @keyup="(e) => $emit('keyup', e)"
          :required="mandotary ? 'true' : 'false'"
          :minlength="minlength"
          :maxlength="maxlength"
          :min="min"
          :max="max"
          :pattern="pattern"
          :placeholder="placeholder"
          :readonly="readonly"
        />
        <strong
          @click="showAreaCode = true"
          class="c-form-control__icon o-form-control__icon with-flag u-font-size-small u-color-muted"
        >
          +{{ activeArea.dialCode }}
        </strong>

        <i
          v-if="type === 'password'"
          @click="showPassword = !showPassword"
          class="c-form-control__password o-form-control__icon"
          :class="`icofont-${showPassword ? 'eye' : 'eye-blocked'}`"
        ></i>
        <div class="c-form-control__error-feedback">
          {{ $t("common.required") }}
        </div>
      </div>
    </div>
    <Modal
      v-model="showAreaCode"
      hide-footer
      :title="$tc('common.countryTitle')"
    >
      <p class="u-color-muted u-margin-bottom">
        {{ $tc("common.selectCountryDescription") }}
      </p>
      <BaseInput
        v-model="search"
        :placeholder="$tc('common.search')"
        icon="search"
        block
      ></BaseInput>
      <ul class="c-list c-list__h-400">
        <li
          class="c-list__item"
          v-for="(country, i) in CountriesFiltered"
          :key="i"
          @click="SELECT_COUNTRY(country)"
        >
          <span>
            <img
              :src="
                require(`@/assets/images/countries/flags/images/${country.iso2}.png`)
              "
              :alt="country.name"
              class="u-margin-right-xsmall"
              width="35"
            />
            (+{{ country.dialCode }}) {{ country.name }}
          </span>
        </li>
      </ul>
    </Modal>
  </div>
</template>

<script>
import Countries from "@/assets/images/countries/countries.json";
import Modal from "@/components/Base/Modal.vue";
import BaseInput from "./BaseInput.vue";
/**
 * The only true button.
 * @displayName Best Button
 */
export default {
  components: { Modal, BaseInput },
  props: {
    /**
     * labeeell
     */
    inputId: {
      default: "",
    },
    label: {
      default: "",
    },
    value: {
      default: "",
    },
    icon: {
      default: "flag",
    },
    placeholder: {
      default: "",
    },
    minlength: {
      default: null,
    },
    maxlength: {
      default: null,
    },
    min: {
      default: null,
    },
    max: {
      default: null,
    },
    pattern: {
      default: null,
    },
    type: {
      default: "text",
    },
    mandotary: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    block: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      search: "",
      showAreaCode: false,
      showPassword: false,
      Countries,
      activeArea: {
        name: "Turkey (Türkiye)",
        iso2: "tr",
        dialCode: "90",
        priority: 0,
        areaCodes: null,
      },
    };
  },
  computed: {
    CountriesFiltered() {
      return this.Countries.filter((country) => {
        return country.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    SELECT_COUNTRY(country) {
      this.activeArea = country;
      this.showAreaCode = false;
      setTimeout(() => {
        document.getElementById("phoneNo").focus();
      }, 360);
    },
    getActiveCountry() {
      return this.activeArea;
    },
  },
};
</script>

<style></style>
